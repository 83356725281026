import { startOfDay, endOfDay } from 'date-fns';
import formatFns from 'date-fns/format';
import intl from 'react-intl-universal';
import replace from 'lodash/replace';

import getUiDate from '@helpers/getUiDate';

import { TFormats } from '@interfaces/index';

export default (date: string | number | Date, format: TFormats): string => {
  const lang = intl.getInitOptions().currentLocale;

  try {
    if (
      lang === 'de-de' &&
      (format === 'dd.MM.yyyy - HH:mm' || format === 'dd MMM yyyy, HH:mm')
    ) {
      return `${formatFns(getUiDate(date), format)} Uhr `;
    }
    return formatFns(getUiDate(date), format);
  } catch (e) {
    if (
      lang === 'de-de' &&
      (format === 'dd.MM.yyyy - HH:mm' || format === 'dd MMM yyyy, HH:mm')
    ) {
      return `${formatFns(getUiDate(date), format)} Uhr `;
    }
    return formatFns(getUiDate(), format);
  }
};

export const dateWithoutTZ = (date: string): Date =>
  new Date(replace(replace(replace(date, 'T', ' '), 'Z', ''), /-/g, '/'));

export const dateToGermanTZ = (date: Date): Date =>
  new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);

export const dateBackFromGermanTZ = (date: Date): Date =>
  new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);

export const dateTodayRange = (
  start?: Date,
  end?: Date,
): { start: string; end: string } => ({
  start: formatFns(startOfDay(start || new Date()), `yyyy-MM-dd HH:mm:ss`),
  end: formatFns(endOfDay(end || new Date()), `yyyy-MM-dd HH:mm:ss`),
});
