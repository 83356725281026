import Cookie from '@packages/cookie';

import { UI_TIME_OFFSET } from '../constants/cookies';

export default (date?: string | number | Date): number | Date => {
  const cookieValue = new Cookie().get(UI_TIME_OFFSET);
  const dateObj = date ? new Date(date) : new Date();

  if (cookieValue !== '') {
    const uiTzOffset = parseInt(cookieValue, 10);
    return (
      dateObj.valueOf() +
      dateObj.getTimezoneOffset() * 60 * 1000 -
      uiTzOffset * 60 * 1000
    );
  }

  return dateObj;
};
